import React, { useState, useEffect } from 'react';
import { Card, Table, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOpenFormationDeals } from '../../../actions/planner';
import { sendCourse } from "../../../actions/course";
import { useTranslation } from "react-i18next";
import { showError, showSuccess, showConfirmation } from "../../../helpers/messages";

const allFields = [
    "id", "curso_id", "ocultar", "titulo", "customer_id", "p_titulo_deal",
    "p_alumnos_totales_formacion", "p_categoria_formacion", "p_hotel_pernocta",
    "p_sede_formacion", "p_fecha_formacion", "p_tipo_servicio", "p_direccion",
    "p_fundae", "fundae_validado", "p_caes", "p_precio_total_deal", "p_orden_compra", "p_documento_pago",
    "p_centro_de_coste", "p_forma_pago", "p_dia_pago", "p_productos_extras",
    "p_persona_contacto", "p_plazas_reservadas", "p_owner_name", "p_canal_origen", "customer_name_pipedrive",
    "url_customer_pipedrive", "url_deal_pipedrive", "codigo_grupo_alumnos",
    "deal_id_pipedrive", "product_id_pipedrive", "gep_tipo_formacion", "planificado",
    "horas", "horas_asistencia", "fecha_inicio", "fecha_fin", "lugar", "ciudad",
    "pais", "precio", "plazas", "ocupadas", "num_unidad", "ciclo_formativo",
    "certificado_generado", "fecha_certificado", "demarcacion_id", "cursos_tipo_id",
    "organizador_id", "formadores_homologado_id", "descripcion", "p_won_time",
    "p_update_time", "created", "modified", "p_webhook_notification", "wc_id_variacion",
    "wc_sku", "wc_order_id", "meses", "estado_general_formacion"
];

const CourseResultsCard = ({ courseTitle, courseId, columns }) => {
    const { openFormationDeals } = useSelector((state) => state.planner);
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            if (courseId) {
                setLoading(true);
                try {
                    const res = await dispatch(fetchOpenFormationDeals({ curso_id: courseId, deal_id_pipedrive: "todos", group: 1 }));
                    setLoading(false);
                    if (!res.ok) {
                        showError(t("message-title-error"), t(res.message));
                    }
                } catch (error) {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [courseId, dispatch, t]);

    useEffect(() => {
        if (openFormationDeals) {
            setResults(openFormationDeals);
        }
    }, [openFormationDeals]);

    const handleSwitchChange = async (checked, record) => {
        console.log('Switch changed for record:', record, 'Checked:', checked);
        // Guarda el estado anterior
        setResults((prevResults) =>
            prevResults.map((r) =>
                r.id === record.id ? { ...r, fundae_validado: checked } : r
            )
        );

        const updatedField = { ['fundae_validado']: checked };
        try {
            const response = await sendCourse({ Cursos: { id: record.id, ...updatedField } })();

            if (response.ok) {
                showSuccess("Correcto", response.message);
            } else {
                throw new Error(response.message);
            }
        } catch (error) {
            // Revert the state change
            setResults((prevResults) => prevResults.map((r) =>
                r.id === record.id ? { ...r, fundae_validado: !checked } : r
            ));
            showError("Error", "message-server-error");
        }
    };

    const defaultColumns = [
        { title: 'Nombre Empresa', dataIndex: 'customer_name_pipedrive', key: 'customer_name_pipedrive' },
        { title: 'Alumnos', dataIndex: 'p_alumnos_totales_formacion', key: 'p_alumnos_totales_formacion' },
        { title: 'Fundae', dataIndex: 'p_fundae', key: 'p_fundae', render: (text) => (text ? 'Sí' : 'No') },
        {
            title: 'Fundae Validado', dataIndex: 'fundae_validado', key: 'fundae_validado',
            render: (text, record) => (
                <Switch
                    checked={record.fundae_validado}
                    onChange={(checked) => handleSwitchChange(checked, record)}
                />
            )
        },
        { title: 'Deal Num', dataIndex: 'deal_id_pipedrive', key: 'deal_id_pipedrive' },
        { title: 'PO', dataIndex: 'p_orden_compra', key: 'p_orden_compra' },
        { title: 'Origen / Canal Deal', dataIndex: 'p_canal_origen', key: 'p_canal_origen' },
        { title: 'Comercial', dataIndex: 'p_owner_name', key: 'p_owner_name', render: (text) => text.toUpperCase() || '-' },
    ];

    const validColumns = columns && columns.length > 0
        ? columns.filter(col => allFields.includes(col.dataIndex))
        : defaultColumns;

    const columnsWithSwitch = validColumns.map(col => {
        if (col.dataIndex === 'fundae_validado') {
            return {
                ...col,
                render: (text, record) => (
                    <Switch
                        checked={record.fundae_validado}
                        onChange={(checked) => handleSwitchChange(checked, record)}
                    />
                )
            };
        }
        return col;
    });

    return (
        <Card
            title={courseTitle}
            loading={loading}
            style={{ maxWidth: '100%', width: '100%' }}
        >
            <Table
                dataSource={results}
                columns={columnsWithSwitch}
                rowKey="id"
                style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
            />
        </Card>
    );
};

export default CourseResultsCard;