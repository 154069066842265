import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import M from 'materialize-css';
import { enable as enableDarkMode, disable as disableDarkMode } from 'darkreader';

import Can from '../../routes/Can';

import { startLogout, storeClean } from '../../actions/auth';
import { setLanguage } from '../../actions/language';
import { deleteCache, startLoading, endLoading } from '../../actions/ui';

import { showError, showSuccess } from '../../helpers/messages';

import MenuBar from './MenuBar';
//import MenuActionButton from './MenuActionButton';
import HeaderLogo from './HeaderLogo';
import MenuBarMobile from './MenuBarMobile';
import Loading from './Loading';
import ActionButton from './ActionButton';

const Header = () => {

    const { i18n, t } = useTranslation();

    const language = useSelector(state => state.lang);

    const dispatch = useDispatch();

    const history = useHistory();

    const [isDark, setIsDark] = useState(localStorage.getItem('theme') === 'dark');

    useEffect(() => {
        const elems = document.querySelectorAll('.translation-button, .profile-button');
        M.Dropdown.init(elems, {
            alignment: "right",
            constrainWidth: false,
            inDuration: 300,
            outDuration: 225,
            hover: false,
            coverTrigger: false,
            closeOnClick: true,
        });

        const tooltips = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(tooltips, {});
    }, []);

    useEffect(() => {
        if (isDark) {
            enableDarkMode({
                brightness: 100,
                contrast: 90,
                sepia: 10,
            });
        } else {
            disableDarkMode();
        }
        localStorage.setItem('theme', isDark ? 'dark' : 'light');
    }, [isDark]);

    const changeLanguage = async (language) => {
        await i18n.changeLanguage(language);
        dispatch(setLanguage(language));    
    };

    const handleToogleFullscreen = () => {

        if (
            (document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)
        ) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            } else if (document.documentElement.msRequestFullscreen) {
                if (document.msFullscreenElement) {
                    document.msExitFullscreen();
                } else {
                    document.documentElement.msRequestFullscreen();
                }
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    };

    const handleLogout = () => {
        dispatch(storeClean());
        dispatch(startLogout());
    };

    const handleDeleteCache = () => {
        dispatch(startLoading());

        dispatch(
            deleteCache()
        ).then((res) => {
            dispatch(endLoading());

            if (res.ok) {
                showSuccess(t('message-title-success'), t(res.message));
            } else {
                showError(t('message-title-error'), t(res.message));
            }
        });
    };

    const handleProfile = () => {
        history.push('/usuarios/perfil');
    };

    const toggleTheme = () => {
        setIsDark((prevIsDark) => !prevIsDark);
    };

    const renderHeader = () => {
        return (
            <>
            <header className="page-topbar" id="header">
                <div className="navbar navbar-fixed"> 
                    
                    {/* Menu superior */}
                    <nav className={`navbar-main navbar-color nav-collapsible sideNav-lock navbar-dark gradient-45deg-light-blue-cyan ${isDark ? 'dark' : 'light'}`}>
                        
                        <div className="nav-wrapper">

                            <HeaderLogo />

                            {/* Menu izquierda */}
                            <ul className="navbar-list right">
                                <li className="delete-cache">
                                    <Can perform="Server:deleteCache" yes={() => (
                                        <ActionButton icon="delete_sweep"
                                            tooltip={ t('screens-list-acciones-cache') } 
                                            extraClass="blue text-black ml-3 transparent" 
                                            onClick={ () => handleDeleteCache() } />
                                    )} />
                                </li>
                                <li className="dropdown-language">
                                    <a className="waves-effect waves-block waves-light translation-button" href="#!" data-target="translation-dropdown">
                                        <span className={`flag-icon flag-icon-${(language === 'pt') ? 'br' : language}`} />
                                    </a>
                                </li>
                                <li className="hide-on-med-and-down">
                                    <a className="waves-effect waves-block waves-light toggle-fullscreen" href="#!" id="fullscreen-toggler" onClick={ handleToogleFullscreen }><i className="material-icons">settings_overscan</i></a>
                                </li>
                                <li className="hide-on-med-and-down">
                                    <a className="waves-effect waves-block waves-light toggle-theme" href="#!" onClick={ toggleTheme }><i className="material-icons">{isDark ? 'brightness_7' : 'brightness_4'}</i></a>
                                </li>
                                <li>
                                    <a className="waves-effect waves-block waves-light profile-button" href="#!" data-target="profile-dropdown"><span className="avatar-status avatar-online"><img src="/assets/images/profile.png" alt="Perfil"/><i></i></span></a>
                                </li>
                            </ul>

                            {/* Submenu idioma */}
                            <ul className="dropdown-content" id="translation-dropdown">
                                <li className="dropdown-item">
                                    <a className="grey-text text-darken-1" href="#!" data-language="es" onClick={ () => changeLanguage('es') }><i className="flag-icon flag-icon-es"></i> Español</a>
                                </li>
                                <li className="dropdown-item">
                                    <a className="grey-text text-darken-1" href="#!" data-language="pt" onClick={ () => changeLanguage('pt') }><i className="flag-icon flag-icon-br"></i> Portuguese</a>
                                </li>
                            </ul>

                            {/* Submenu perfil */}
                            <ul className="dropdown-content" id="profile-dropdown">
                                <li>
                                    <a className="grey-text text-darken-1" href="#!" onClick={ handleProfile }>
                                        <i className="material-icons">person_outline</i> 
                                        { t('screens-user-profile-title') }
                                    </a>
                                </li>
                                <li>
                                    <a className="grey-text text-darken-1" href="#!" onClick={ handleLogout }>
                                        <i className="material-icons">keyboard_tab</i> 
                                        { t('Logout') }
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>

                    {/* Menu horizontal */}
                    <MenuBar />

                </div>

                <Loading />
            </header>
            
            <MenuBarMobile />
            </>
        );

    };

    return renderHeader();

};

export default Header;